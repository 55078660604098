<template>
  <div>
    <div class="video-player" ref="videoContainer">
      <video
        class="w-full md:rounded-md video bg-gradient-to-b from-scorelitorange to-scorelitred"
        v-bind:class="!openDrawing ? 'visible' : 'invisible h-0'"
        ref="videoPlayer"
        :poster="thumbnail"
        preload="none"
        v-on:timeupdate="updateProgress"
        v-on:click="togglePlayPause"
        crossOrigin="anonymous"
      >
        <source :src="videoUrl" />
      </video>
      <div class="video-player-controls">
        <div class="controls" data-state="hidden">
          <div class="left-aligned-controls">
            <button
              ref="playpause"
              type="button"
              data-state="play"
              class="play"
              v-on:click="togglePlayPause"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="player-play"
              >
                <path
                  d="M11.5369 7.22801C10.8711 6.80437 10 7.28258 10 8.07167V21.9283C10 22.7174 10.8712 23.1956 11.5369 22.772L22.4242 15.8437C23.0417 15.4507 23.0417 14.5493 22.4242 14.1563L11.5369 7.22801Z"
                  fill="white"
                ></path>
              </svg>
            </button>
            <button
              ref="forward"
              type="button"
              class="jump prev"
              v-on:click="fastBackward"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="player-next"
              >
                <path
                  d="M8 21.035C8 21.4404 8.45713 21.6773 8.78834 21.4435L17.338 15.4085C17.6202 15.2093 17.6202 14.7907 17.338 14.5915L8.78834 8.55648C8.45714 8.32268 8 8.55955 8 8.96496V21.035ZM19.6667 8V22H22V8H19.6667Z"
                  fill="white"
                ></path>
              </svg>
            </button>
            <button
              ref="forward"
              type="button"
              class="jump next"
              v-on:click="fastForward"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="player-next"
              >
                <path
                  d="M8 21.035C8 21.4404 8.45713 21.6773 8.78834 21.4435L17.338 15.4085C17.6202 15.2093 17.6202 14.7907 17.338 14.5915L8.78834 8.55648C8.45714 8.32268 8 8.55955 8 8.96496V21.035ZM19.6667 8V22H22V8H19.6667Z"
                  fill="white"
                ></path>
              </svg>
            </button>
            <button
              ref="forward"
              type="button"
              class="video__play ml-4"
              v-on:click="slowMotion"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 576 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="my-auto"
              >
                <path
                  v-bind:fill="slowMotionControls ? 'white' : 'gray'"
                  d="M68.25 256h279.51c23.54 0 40.97-19.8 35.1-40.04C362.84 146.97 292.33 64 208.41 64h-.82c-83.91 0-154.43 82.97-174.44 151.96C27.27 236.2 44.71 256 68.25 256zm484.03-118.75l-48.65-34.75c-35.17-17.42-80.49 1.57-86.81 40.31-.54 3.32-.82 6.72-.82 10.19v71.22c-.03 13.88-4.6 27.18-13.27 38.44-12.42 16.11-31.25 25.34-51.68 25.34H18.6C8.33 288 0 296.33 0 306.6c0 8 5.12 15.11 12.71 17.64l98.29 22.1L66.17 424c-6.16 10.67 1.54 24 13.86 24h36.95c5.71 0 11-3.05 13.86-8l40.3-69.8c25.99 8.52 45.55 13.8 84.87 13.8s58.89-5.28 84.87-13.8l40.3 69.8c2.86 4.95 8.14 8 13.86 8h36.95c12.32 0 20.01-13.33 13.86-24l-47.21-81.76c21.25-8.42 40.36-21.78 54.81-40.53 14.08-18.28 22.47-39.4 25.29-61.7h40.62c31.29 0 56.65-25.36 56.65-56.65a56.7 56.7 0 0 0-23.73-46.11zM480 176c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"
                ></path>
              </svg>
            </button>
          </div>
          <div class="position-display unselectable">
            <span class="text-xs text-gray-300 font-normal"
              >{{ timeNow }} |</span
            >
            <span class="text-xs text-gray-300 font-normal ml-1">{{
              duration
            }}</span>
          </div>
          <div class="right-aligned-controls relative">
            <button
              type="button"
              class=""
              v-on:click="volumeControls = !volumeControls"
            >
              <svg
                width="27"
                height="27"
                viewBox="0 0 26 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="player-volume my-auto"
              >
                <path
                  fill="white"
                  d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                />
              </svg>
            </button>
            <div
              class="bg-gray-900 h-2 w-20 absolute rounded inline-block origin-left transform -rotate-90 float-left mb-10"
              style="left: 10px; bottom: 35px"
              v-bind:class="volumeControls ? 'visible' : 'invisible'"
            >
              <label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  class="w-full float-left relative"
                  style="top: -5px; z-index: 10; opacity: 0; left: 2px"
                  v-on:input="setVolume()"
                  v-model="volume"
                />
              </label>
              <span
                ref="volume"
                class="rounded h-2 relative inline-block bg-gradient-to-r from-gradient-orange to-gradient-red float-left relative"
                style="top: -16px;"
              ></span>
            </div>
            <button
              class="fullscreen"
              data-state="go-fullscreen"
              v-on:click="goFullScreen"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="player-fullscreen"
              >
                <path
                  d="M8.75 17.5H6.25V21.75C6.25 22.8546 7.14543 23.75 8.25 23.75H12.5V21.25H8.75V17.5ZM6.25 12.5H8.75V8.75H12.5V6.25H8.25C7.14543 6.25 6.25 7.14543 6.25 8.25V12.5ZM21.25 21.25H17.5V23.75H21.75C22.8546 23.75 23.75 22.8546 23.75 21.75V17.5H21.25V21.25ZM17.5 6.25V8.75H21.25V12.5H23.75V8.25C23.75 7.14543 22.8546 6.25 21.75 6.25H17.5Z"
                  fill="white"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="progress">
          <input
            type="range"
            min="0"
            max="500"
            step="1"
            class="seeker"
            v-model="progressValue"
            v-on:input="updateValue"
          />
          <span
            ref="progress"
            class="buffer bg-gradient-to-r from-gradient-orange to-gradient-red"
          ></span>
        </div>
        <figure
          class="controls-mask"
          role="presentation"
          v-on:click="togglePlayPause"
        ></figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: ["videoUrl", "reviewId", "thumbnail"],
  data() {
    return {
      // video: this.videoUrl,
      fullScreen: false,
      openDrawing: false,
      timeNow: "00:00",
      duration: "00:00",
      keyFrame: undefined,
      progressValue: 0,
      volumeControls: false,
      volume: 50,
      slowMotionControls: false,
      videoPlaying: false
    };
  },
  created() {},
  mounted() {
    this.setControls();
    this.setVolume();
    this.addEndedListenerToVideo();
  },
  methods: {
    addEndedListenerToVideo() {
      // add listener to video to determine if the video ended
      const videoplayer = this.$refs.videoPlayer;
      videoplayer.addEventListener("ended", () => {
        this.$emit("video-ended");
      });
    },
    getKeyFrame() {
      let tmpCanvas = document.createElement("canvas");
      tmpCanvas.width = this.$refs.videoPlayer.videoWidth;
      tmpCanvas.height = this.$refs.videoPlayer.videoHeight;
      this.$refs.videoPlayer.crossOrigin = "unanimous";
      tmpCanvas
        .getContext("2d")
        .drawImage(
          this.$refs.videoPlayer,
          0,
          0,
          tmpCanvas.width,
          tmpCanvas.height
        );

      this.keyFrame = tmpCanvas.toDataURL();
    },
    updateValue() {
      this.$refs.videoPlayer.currentTime =
        this.$refs.videoPlayer.duration * (this.progressValue / 500);
    },
    slowMotion() {
      if (!this.slowMotionControls) {
        this.$refs.videoPlayer.playbackRate = 0.25;
      } else {
        this.$refs.videoPlayer.playbackRate = 1;
      }
      this.slowMotionControls = !this.slowMotionControls;
    },
    fastBackward() {
      this.$refs.videoPlayer.currentTime -= 3;
    },
    fastForward() {
      this.$refs.videoPlayer.currentTime += 3;
    },
    setControls() {
      this.$refs.videoPlayer.controls = false;
    },
    setVolume() {
      this.$refs.videoPlayer.volume = this.volume / 100;
      this.$refs.volume.style.width = this.volume + "%";
    },
    togglePlayPause() {
      if (this.$refs.videoPlayer.paused || this.$refs.videoPlayer.ended) {
        this.openDrawing = false;
        this.videoPlaying = true;
        this.$refs.playpause.innerHTML =
          '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="player-pauze"><path d="M7.5 23.75H12.5V6.25H7.5V23.75ZM17.5 6.25V23.75H22.5V6.25H17.5Z" fill="white"></path></svg>';
        this.$refs.videoPlayer.play();
      } else {
        this.videoPlaying = false;
        this.$refs.playpause.innerHTML =
          '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="player-play"><path d="M11.5369 7.22801C10.8711 6.80437 10 7.28258 10 8.07167V21.9283C10 22.7174 10.8712 23.1956 11.5369 22.772L22.4242 15.8437C23.0417 15.4507 23.0417 14.5493 22.4242 14.1563L11.5369 7.22801Z" fill="white"></path></svg>';
        this.$refs.videoPlayer.pause();
      }
    },
    parseTimeSeconds(time) {
      let minutes = 0;
      if (time > 60) {
        minutes = Math.floor(time / 60);
        time -= minutes * 60;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (time <= 9) {
        time = "0" + time;
      }
      return minutes + ":" + time;
    },
    updateProgress() {
      let time = this.$refs.videoPlayer.currentTime.toFixed();
      this.timeNow = this.parseTimeSeconds(time);
      this.duration = this.parseTimeSeconds(
        Math.floor(this.$refs.videoPlayer.duration)
      );

      let value = 0;
      if (this.$refs.videoPlayer.currentTime > 0) {
        value = Math.floor(
          (100 / this.$refs.videoPlayer.duration) *
            this.$refs.videoPlayer.currentTime
        );
      }
      if (this.$refs.progress !== null) {
        this.$refs.progress.style.width = value + "%";
      }
    },
    goFullScreen() {
      if (this.$refs.videoContainer.requestFullscreen) {
        if (this.fullScreen) {
          document.exitFullscreen();
        } else {
          this.$refs.videoContainer.requestFullscreen();
        }
      } else if (this.$refs.videoContainer.mozRequestFullScreen) {
        /* Firefox */
        if (this.fullScreen) {
          document.mozCancelFullScreen();
        } else {
          this.$refs.videoContainer.mozRequestFullScreen();
        }
      } else if (this.$refs.videoContainer.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        if (this.fullScreen) {
          document.webkitExitFullscreen();
        } else {
          this.$refs.videoContainer.webkitRequestFullscreen();
        }
      } else if (this.$refs.videoContainer.msRequestFullscreen) {
        if (this.fullScreen) {
          document.msExitFullscreen();
        } else {
          this.$refs.videoContainer.msRequestFullscreen();
        }
      }
      this.fullScreen = !this.fullScreen;
    },
    parseTime(time) {
      let hours = 0;
      while (time > 3600) {
        time -= 3600;
        hours++;
      }
      let minutes = 0;
      while (time > 60) {
        time -= 60;
        minutes++;
      }
      let seconds = time;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        if (seconds % 1 === 0) {
          seconds = seconds + ".000";
        }
        seconds = "0" + parseInt(seconds).toFixed(3);
      } else {
        seconds = parseInt(seconds).toFixed(3);
      }
      return hours + ":" + minutes + ":" + seconds;
    }
  }
};
</script>

<style scoped>
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
}

.video-player {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-player-controls {
  color: var(--color-text-primary);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.video-player-controls .controls {
  bottom: 0;
  display: grid;
  grid-template-areas: "left center right";
  grid-template-columns: 33% 34% 33%;
  position: absolute;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  width: 100%;
  z-index: 2;
}

.video-player-controls .controls > * {
  margin: calc(1rem - 4px) 0 1rem;
}

.video-player-controls .controls > .next,
.video-player-controls .controls > .play,
.video-player-controls .controls > .prev {
  grid-area: left;
  justify-self: left;
}

.video-player-controls .controls > .left-aligned-controls,
.video-player-controls .controls > .right-aligned-controls {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.video-player-controls .controls > .left-aligned-controls > *,
.video-player-controls .controls > .right-aligned-controls > * {
  margin: 0 0.875rem;
}

.video-player-controls .controls > .left-aligned-controls {
  grid-area: left;
  justify-self: start;
}

.video-player-controls .controls > .left-aligned-controls > :first-child {
  margin-left: 1rem;
}

.video-player-controls .controls > .position-display {
  align-self: center;
  grid-area: center;
  justify-self: center;
}

.video-player-controls .controls > .right-aligned-controls {
  grid-area: right;
  justify-self: end;
}

.video-player-controls .controls > .right-aligned-controls > :last-child {
  margin-right: 1rem;
}

.video-player-controls .controls button {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}

.video-player-controls .controls button.jump {
  opacity: 0.5;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.video-player-controls .controls button.jump:active,
.video-player-controls .controls button.jump:focus,
.video-player-controls .controls button.jump:hover {
  opacity: 1;
}

.video-player-controls .controls button.prev svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.video-player-controls .controls .current-position,
.video-player-controls .controls .duration {
  -ms-flex-item-align: center;
  align-self: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.8125rem;
}

.video-player-controls .seeker {
  background-color: rgba(0, 0, 0, 0.3);
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 100%;
  z-index: 5;
  opacity: 0;
}

.video-player-controls .progress {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 3.75rem;
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.video-player-controls .progress .buffer,
.video-player-controls .progress .position {
  height: 4px;
  position: absolute;
  -webkit-transition: width 0.1s linear;
  transition: width 0.1s linear;
}

.video-player-controls .progress .buffer {
  background-color: hsla(0, 0%, 100%, 0.3);
}
</style>
