<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">

                <div class="flex mb-8 ">
                    <router-link :to="{name: 'matches.index'}" class="text-gray-500 uppercase text-xl font-extrabold hover:text-white transition-all duration-200">
                        Wedstrijden
                    </router-link>
                    <svg class=" h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path class="my-auto" fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <h1 class="text-white uppercase text-xl font-extrabold">
                        {{ $route.params.id }}
                    </h1>
                </div>
                
                <Video video-url="video.path" :thumbnail="video.thumbnail" class="mb-10"/>

                <div class="grid gap-10 lg:grid-cols-3"> 
                    <div class="bg-scorelitgray px-8 py-8 lg:col-span-2 shadow sm:rounded-md">
                        <div class="sm:flex border-b">
                            <h2 class="text-white text-xl font-bold pb-3">
                                {{ video.title }}
                            </h2>
                            <div class="ml-auto my-auto mt-1 text-white">
                                <div @click="showModal('share')" class="inline-flex mr-4">
                                    <svg class="h-5 w-5 mr-1 transition-all duration-150 hover:text-gray-400 transform hover:scale-125 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                    </svg>
                                </div>
                                <div @click="showModal('edit')" class="inline-flex mr-4">
                                    <svg class="h-5 w-5 mr-1 transition-all duration-150 hover:text-gray-400 transform hover:scale-125 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </div>
                                <div @click="showModal('delete')" class="inline-flex">
                                    <svg class="h-5 w-5 mr-1 transition-all duration-150 hover:text-scorelitred transform hover:scale-125 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg> 
                                </div>
                            </div>
                        </div>
                        <h3 class="text-white mt-6 font-bold">
                            Team
                        </h3>
                        <p class="text-white text-sm mt-1">
                            {{ video.team }}
                        </p>
                        <h3 class="text-white mt-6 font-bold">
                            Aangemaakt op
                        </h3>
                        <p class="text-white text-sm mt-1">
                            {{ video.date }}
                        </p>
                        <h3 class="text-white mt-6 font-bold">
                            Beschrijving
                        </h3>
                        <p class="text-white text-sm mt-1">
                            {{ video.description }}
                        </p>
                    </div>

                    <div class="bg-scorelitgray px-8 py-10 shadow sm:rounded-md">
                        <div class="flex border-b pb-2">
                            <h3 class="text-white font-bold ">
                                Coaches van deze wedstrijd
                            </h3>
                            <svg @click="showModal('addCoach')" xmlns="http://www.w3.org/2000/svg" class="ml-auto text-white hover:text-gray-400 cursor-pointer h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <ul class="text-white text-sm ml-2">
                            <li v-for="coach in video.coaches" :key="coach" class="mt-2 group flex hover:text-scorelitred">
                                {{ coach }}
                                <svg class="ml-auto invisible group-hover:visible h-5 w-5 text-scorelitred cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                                </svg>
                            </li>
                        </ul>
                
                        <div class="flex border-b pb-2 mt-6">
                            <h3 class="text-white font-bold">
                                Spelers in wedstrijd
                            </h3>
                            <svg @click="showModal('addPlayer')" xmlns="http://www.w3.org/2000/svg" class="ml-auto text-white hover:text-gray-400 cursor-pointer h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <ul class="text-white text-sm ml-2">
                            <li v-for="(player, index) in video.players" :key="player" class="mt-2 group flex hover:text-scorelitred">
                                {{ index + 1 }} {{ player }}
                                <svg class="ml-auto invisible group-hover:visible h-5 w-5 text-scorelitred cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                                </svg>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>

    <!-- Share Match Modal-->
    <Modal v-if="modalVisible && selectedModal == 'share'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd delen
                </h2>
            </div>
        </template>
        <template v-slot:body>  
            <p class="pb-6 mt-2">
                Code ophalen of e-mail sturen?
            </p>
            <TextInput label="E-mailadres" border="enable" placeholder="E-mailadres" />
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Opslaan
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Edit Match Modal-->
    <Modal v-if="modalVisible && selectedModal == 'edit'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd aanpassen
                </h2>
            </div>
        </template>
        <template v-slot:body>  
            <p class="pb-6 mt-2">
                Eventuele tekst
            </p>
            <TextInput label="Titel" border="enable" placeholder="Titel"/>
            <SelectInput label="Team" border="enable" class="mt-5"/>
            <TextAreaInput label="Beschrijving" border="enable" class="mt-5"/>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Opslaan
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Delete Match Modal-->
    <Modal v-if="modalVisible && selectedModal == 'delete'" @closed="closeModal">
         <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd verwijderen
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <p class="pb-6 mt-2">
                Weet je zeker dat je de wedstrijd <strong>???</strong> wilt verwijderen?
            </p>
        </template>
        <template v-slot:footer>        
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Verwijderen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Add Coaches Modal -->
    <Modal v-if="modalVisible && selectedModal == 'addCoach'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Coach toevoegen
                </h2>
            </div>
        </template>
        <template v-slot:body>  
            <p class="pb-6 mt-2">
                Voeg een coach toe aan deze wedstrijd
            </p>
            <SearchInput value="" border="enable" placeholder="Coach zoeken.."/>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Toevoegen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Add Player Modal -->
    <Modal v-if="modalVisible && selectedModal == 'addPlayer'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Speler toevoegen
                </h2>
            </div>
        </template>
        <template v-slot:body>   
            <p class="pb-6 mt-2">
                Voeg speler(s) toe aan deze wedstrijd
            </p>
            <SearchInput value="" border="enable" placeholder="Speler zoeken.."/>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Toevoegen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import Video from "@/views/matches/Video";
import TextInput from "@/components/forms/TextInput";
import SearchInput from "@/components/forms/SearchInput";
import SelectInput from "@/components/forms/SelectInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import Modal from "@/components/modals/Modal";


export default {
    name: "Match",
    props: ['match'],

    components: {
        DashboardWrapper,
        Video,
        TextInput,
        SearchInput,
        SelectInput,
        TextAreaInput,
        Modal, 
    },

    data() {
        return {
            modalVisible: false,
            selectedModal: '',

            video: { 
                title: 'Titel van de wedstrijd', 
                team: 'MU22-1', 
                date: '22-4-2021', 
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                coaches: ['Voornaam Achternaam', 'Voornaam Achternaam'],
                players: ['Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam', 'Voornaam Achternaam'],
            }
        }
    },

    methods: {
        closeModal() {
            this.modalVisible = false;
        },

        showModal(type) {
            this.selectedModal = type;
            this.modalVisible = true;        
        },
    },
}
</script>